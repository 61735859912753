(function () {
        'use strict';

        angular
            .module('aditumApp')
            .controller('AdministrativeMobileMenuController', AdministrativeMobileMenuController);

        AdministrativeMobileMenuController.$inject = ['$timeout', '$scope', '$stateParams', '$rootScope', 'CommonMethods', 'globalCompany','$state','AdministrationConfiguration'];

        function AdministrativeMobileMenuController(timeout, $scope, $stateParams, $rootScope, CommonMethods, globalCompany,$state,AdministrationConfiguration) {
            var vm = this;
            $rootScope.active = "administrative-mobile-menu";
            $rootScope.mainTitle = "Administrativo";
            vm.go = function (sref) {
                $rootScope.backState = $state.current.name
                $state.go(sref)
            }

            AdministrationConfiguration.get({companyId:globalCompany.getId()},function(result){
                vm.menu = [
                    {
                        title: "Observaciones",
                        icon: "record_voice_over",
                        authoritites: "ROLE_USER,ROLE_OWNER",
                        uisref: "complaint-user",
                        show: true,
                        class:"animate__animated  animate__backInLeft"
                    },

                    {
                        title: "Revisiones rutinarias",
                        icon: "assignment_turned_in",
                        authoritites: "ROLE_USER,ROLE_OWNER",
                        uisref: "revision",
                        show:result.hideRoutineCheck ,
                        class:"animate__animated  animate__backInRight"
                    },
                    {
                        title: "Documentos",
                        icon: "description",
                        authoritites: "ROLE_USER,ROLE_OWNER",
                        uisref: "documentation-mobile-menu",
                        show: true,
                        class:"animate__animated  animate__backInLeft"
                    },
                    {
                        title: "Solicitudes",
                        icon: "forum",
                        authoritites: "ROLE_USER,ROLE_OWNER",
                        uisref: "individual-release-user",
                        show: $rootScope.adminCompany.id == 1 && (globalCompany.getId()!= 60 && globalCompany.getId()!=91) ,
                        class:"animate__animated  animate__backInRight"
                    },
                    {
                        title: "Contactos",
                        icon: "contact_phone",
                        authoritites: "ROLE_USER,ROLE_OWNER",
                        uisref: "administration-contact",
                        show: true,
                        class:"animate__animated  animate__backInRight"
                    },
                ]
            })
        }
    }

)();
