(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('VisitantInvitedAdminViewController', VisitantInvitedAdminViewController);

    VisitantInvitedAdminViewController.$inject = ['Company', '$scope', 'AdministrationConfiguration', '$localStorage', 'InvitationSchedule', 'VisitantInvitation', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'Principal', '$rootScope', '$state', 'CommonMethods', 'WSVisitorInvitation', 'WSDeleteEntity', 'globalCompany', 'Modal', 'House', 'CompanyConfiguration', 'GateAccess'];

    function VisitantInvitedAdminViewController(Company, $scope, AdministrationConfiguration, $localStorage, InvitationSchedule, VisitantInvitation, ParseLinks, AlertService, paginationConstants, pagingParams, Principal, $rootScope, $state, CommonMethods, WSVisitorInvitation, WSDeleteEntity, globalCompany, Modal, House, CompanyConfiguration, GateAccess) {
        $rootScope.active = "visitantsAdminView";
        var vm = this;

        vm.timeFormat = "TEMPORAL";

        Company.get({
            id: globalCompany.getId()
        }, function (data) {
            vm.isFullAccess = data.fullControlAccess;
            vm.onlyControlAccess = data.onlyControlAccess;
            vm.company = data;
        })

        vm.qrCode = false;

        CompanyConfiguration.get({id: globalCompany.getId()}, function (data) {
            vm.companyConfig = data;
            if (vm.companyConfig.hasAditumGate && vm.companyConfig.hasQrCodeGate) {
                GateAccess.queryByCompany({companyId: globalCompany.getId()}, function (data) {
                    if (data.length != 0) {
                        vm.qrCode = true;
                    }
                })
            }
        })


        function getCurrentDomain() {
            // Get the full URL of the current page
            var currentUrl = window.location.href;

            // Create an anchor element to parse the URL
            var parser = document.createElement('a');
            parser.href = currentUrl;

            // Access the 'hostname' property to get the domain
            var domain = parser.hostname;

            return domain;
        }

        vm.showQrCode = function(visitor){
            return visitor.visitorType != 'EXPRESS';
        }

        vm.wspText = function (visitor) {
            var message = "Hola! Te he autorizado para poder ingresar a " + vm.company.name + "! ☺️ %0a" + "%0a" + "Usa el enlace para obtener el código QR que te permitirá acceder a la entrada automáticamente. Solo acércalo al lector en la entrada y listo. 😉%0a" + "%0a" + "*Enlace de ingreso:* " + "https://" + getCurrentDomain() + "%2F%23%2Fqr%2F" + visitor.code + "%0a" + "%0a" + "Dentro del enlace podrás encontrar la ubicación por Waze para poder llegar.📍%0a" + "%0a" + "Si quieres saber más de ADITUM ingresa a aditumcr.com 📲";
            return message;
        }

        vm.deviceCopyText = function (visitor) {
            var message = "Hola! Te he autorizado para poder ingresar a " + vm.company.name + "! ☺️" + "\n" + "Usa el enlace para obtener el código QR que te permitirá acceder a la entrada automáticamente. Solo acércalo al lector en la entrada y listo. 😉" + "\n" + "*Enlace de ingreso:* " + "https://" + getCurrentDomain() + "/#/qr/" + visitor.code + "" + "\n" + "Dentro del enlace podrás encontrar la ubicación por Waze para poder llegar.📍" + "\n" + "Si quieres saber más de ADITUM ingresa a aditumcr.com 📲";
            return message;
        }


        vm.copyToDevice = function (visitor) {
            var textoACopiar = vm.deviceCopyText(visitor)
            var elementoTemporal = document.createElement('textarea');
            elementoTemporal.value = textoACopiar;
            elementoTemporal.style.position = 'fixed';
            elementoTemporal.style.opacity = 0;
            document.body.appendChild(elementoTemporal);
            elementoTemporal.select();
            document.execCommand('copy')
            Modal.toast("Copiado al portapapeles.")
            try {
                var exito = document.execCommand('copy');
                if (exito) {
                    console.log('Texto copiado al portapapeles');
                } else {
                    console.error('No se pudo copiar al portapapeles');
                }
            } catch (err) {
                console.error('Error al copiar al portapapeles');
            } finally {
                document.body.removeChild(elementoTemporal);
            }
        };

        vm.changeHouse = function (house, i) {
            vm.isReady = false;
            vm.page = 0;
            vm.links = {
                last: 0
            };
            vm.residents = [];
            $localStorage.infoHouseNumber = house;
            vm.infoHouseResident = house;
            vm.selectedIndex = i;
            // $rootScope.mainTitle = "Invitaciones " + house.housenumber;
            loadAll("TEMPORAL");
        };

        vm.selectHouse = function (house) {
            vm.houseSelected = house;
            vm.loadVisitors(vm.timeFormat);
        }

        vm.registerVisitor = function () {
            switch (vm.timeFormat) {
                case "EXPRESS":
                    $state.go("visitant-invited-express-new");
                    break;
                case "PERMANENT":
                    $state.go("visitant-invited-permanent-new");
                    break;
                case "TEMPORAL":
                    $state.go("visitant-invited-temporal-new");
                    break;
                case "BUS":
                    $state.go("visitant-invited-bus-new");
                    break;
                case "VACATIONAL":
                    $state.go("visitant-invited-vacational-new");
                    break;
                case "EMPLOYEE":
                    $state.go("visitant-invited-employee-new");
                    break;
                case "EMPLOYEE":
                    $state.go("visitant-invited-employee-new");
                    break;
                case "IMPORTATION":
                    $state.go("visitant-invited-importations-new");
                    break;
                case "NATIONAL_MERCHANDISE":
                    $state.go("visitant-invited-national-merchandise-ingress-new");
                    break;
                case "MERCHANDISE_EXIT":
                    $state.go("visitant-invited-national-merchandise-exit-new");
                    break;
                    s
                case "MAINTENANCE":
                    $state.go("visitant-invited-maintenance-new");
                    break;
            }
        }

        vm.loadVisitors = function (visitorType) {
            vm.isReady = false;
            loadAll(visitorType);
            $localStorage.timeFormat = vm.timeFormat;
        }

        vm.clearSearchTerm = function () {
            $scope.searchHouse = '';
        };
        vm.typingSearchTerm = function (ev) {
            ev.stopPropagation();
        }

        vm.houseSelected = $localStorage.houseSelected;

        House.query({companyId: globalCompany.getId()}).$promise.then(onSuccessHouses);

        function onSuccessHouses(data, headers) {
            vm.houses = data;
            if (vm.houses.length != 0) {
                vm.houseSelected = vm.houses[0];
                console.log(vm.houseSelected)
                loadAll("TEMPORAL");
            }
        }

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;


        vm.cancelInvitation = function (visitor) {
            Modal.confirmDialog("¿Está seguro que desea revocar el permiso de acceso a " + visitor.name + " " + visitor.lastname + "?", "", function () {
                Modal.showLoadingBar();
                visitor.status = 2;
                VisitantInvitation.update(visitor, success)
            })
        };

        function success(data) {
            WSVisitorInvitation.sendActivity(data);
            Modal.hideLoadingBar();
            Modal.toast("Se ha cancelado la invitación correctamente");
        }

        vm.deleteInvitedVisitor = function (visitor) {
            Modal.confirmDialog("¿Está seguro que desea eliminar el registro?", "", function () {
                Modal.showLoadingBar();
                VisitantInvitation.delete({
                    id: visitor.id
                }, successDelete);
            })

            function successDelete() {
                loadAll("TEMPORAL");
                Modal.toast("Se ha eliminado el registro correctamente");
                Modal.hideLoadingBar();
                WSDeleteEntity.sendActivity({type: 'visitor', id: visitor.id})
            }
        }
        vm.renewVisitorWithSchedule = function (visitor) {
            var encryptedId = CommonMethods.encryptIdUrl(visitor.id)
            $state.go('visitant-invited-admin-view.editSchedule', {
                id: encryptedId
            })
        }
        vm.renewVisitor = function (visitor) {
            var encryptedId = CommonMethods.encryptIdUrl(visitor.id)
            $state.go('visitant-invited-admin-view.edit', {
                id: encryptedId
            })
        }

        AdministrationConfiguration.get({companyId: globalCompany.getId()}, function (entity) {
            vm.administrationConfiguration = entity;
        })

        vm.showFile = function (url) {
            $state.go("visitant-invited-admin-view.file-preview",
                {
                    url: url,
                    type: "image"
                })
        }

        function loadAll(visitorType) {
            vm.timeFormat = visitorType;
            vm.isReady = false;
            VisitantInvitation.findInvitedByHouse({
                companyId: globalCompany.getId(),
                houseId: vm.houseSelected.id,
                visitorType: visitorType
            }).$promise.then(onSuccess);

            function onSuccess(data) {
                if (vm.timeFormat == 0) {
                    angular.forEach(data, function (value, key) {
                        var secondlN = value.secondlastname != null ? value.secondlastname : "";
                        value.fullName = value.name + " " + value.lastname + " " + secondlN;
                        if (value.identificationnumber == "") {
                            value.identificationnumber = null;
                        }
                    });

                } else {
                    angular.forEach(data, function (value, key) {
                        var secondlN = value.secondlastname != null ? value.secondlastname : "";
                        value.fullName = value.name + " " + value.lastname + " " + secondlN;
                        if (value.identificationnumber == "") {
                            value.identificationnumber = null;
                        }
                        InvitationSchedule.findSchedulesByInvitation({
                            invitationId: value.id
                        }, function (result) {
                            value.lunes = result[0].lunes;
                            value.martes = result[0].martes;
                            value.miercoles = result[0].miercoles;
                            value.jueves = result[0].jueves;
                            value.viernes = result[0].viernes;
                            value.sabado = result[0].sabado;
                            value.domingo = result[0].domingo;

                        });
                    });

                }
                vm.visitants = data;
                vm.page = pagingParams.page;
                vm.isReady = true;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        vm.changeTimeFormat = function (timeFormat) {
            vm.isReady = false;
            loadAll("TEMPORAL");
            $localStorage.timeFormat = timeFormat;
        }
        vm.visitorProveedor = function (visitor) {
            if (visitor.proveedor == null || visitor.proveedor == undefined || visitor.proveedor == "") {
                return false;
            }
            return true;
        }
        vm.isBetweenDate = function (visitor) {
            var currentTime = new Date().getTime();
            var intiTime = new Date(visitor.invitationstartingtime).getTime();
            var finalTime = new Date(visitor.invitationlimittime).getTime();
            if (intiTime <= currentTime && currentTime <= finalTime) {
                return true;
            } else {
                return false
            }
        };
        vm.hasPermission = function (visitor) {
            if (visitor.status == 2) {
                return false;
            }
            return vm.isBetweenDate(visitor)
        };
        vm.hasPermissionSchedule = function (visitor) {
            if (visitor.status == 2) {
                return false;
            } else {
                return true;
            }
        }
    }
})();
