(function () {
        'use strict';

        angular
            .module('aditumApp')
            .controller('GenerateQrCodeController', GenerateQrCodeController);

        GenerateQrCodeController.$inject = ['$timeout', '$scope', '$stateParams', '$rootScope', 'CommonMethods', 'globalCompany', '$state'];

        function GenerateQrCodeController(timeout, $scope, $stateParams, $rootScope, CommonMethods, globalCompany, $state) {
            var vm = this;
            $rootScope.active = "security-mobile-menu";
            $rootScope.mainTitle = "Generar QR Personal";
            vm.go = function (sref) {
                $rootScope.backState = $state.current.name
                var parameter = sref.split("/");
                $state.go(parameter[0], {type: parameter[1]})
            }
            vm.menu = [
                {
                    title: "Código de Ingreso",
                    icon: "qr_code",
                    authoritites: "ROLE_USER,ROLE_OWNER",
                    uisref: "generate-qr-code/entry",
                    show: true,
                    class: "animate__animated  animate__backInLeft"
                },
                {
                    title: "Código de Salida",
                    icon: "qr_code_2",
                    authoritites: "ROLE_USER,ROLE_OWNER",
                    uisref: "generate-qr-code/exit",
                    show: true,
                    class: "animate__animated  animate__backInRight"
                }
            ]
        }
    }
)();
