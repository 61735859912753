(function () {
        'use strict';

        angular
            .module('aditumApp')
            .controller('RegisterVisitorMenuController', RegisterVisitorMenuController);

        RegisterVisitorMenuController.$inject = ['$timeout', '$scope', '$stateParams', '$rootScope', 'CommonMethods', 'globalCompany','$state'];

        function RegisterVisitorMenuController(timeout, $scope, $stateParams, $rootScope, CommonMethods, globalCompany,$state) {
            var vm = this;
            $rootScope.active = "register-visitor-mobile-menu";
            $rootScope.mainTitle = "Reportar autorizado";
            vm.go = function (sref) {
                $rootScope.backState = $state.current.name
                $state.go(sref)
            }

            vm.isFullAcess = $rootScope.company.fullControlAccess;

            if(vm.isFullAcess){
                vm.menu = [
                    {
                        title: "Autorizado Temporal",
                        icon: "timer",
                        authoritites: "ROLE_USER,ROLE_OWNER",
                        uisref: "visitant-invited-temporal-new",
                        show: true,
                        class:"animate__animated  animate__backInLeft"
                    },
                    {
                        title: "Ingreso mercadería nacional",
                        icon: "local_shipping",
                        authoritites: "ROLE_USER,ROLE_OWNER",
                        uisref: "visitant-invited-national-merchandise-ingress-new",
                        show: true,
                        class:"animate__animated  animate__backInRight"
                    },
                    {
                        title: "Salida de mercadería",
                        icon: "shopping_cart_checkout",
                        authoritites: "ROLE_USER,ROLE_OWNER",
                        uisref: "visitant-invited-national-merchandise-exit-new",
                        show: true,
                        class:"animate__animated  animate__backInLeft"
                    },
                    {
                        title: "Importación",
                        icon: "luggage",
                        authoritites: "ROLE_USER,ROLE_OWNER",
                        uisref: "visitant-invited-importations-new",
                        show: true,
                        class:"animate__animated  animate__backInRight"
                    },
                    {
                        title: "Mantenimientos",
                        icon: "construction",
                        authoritites: "ROLE_USER,ROLE_OWNER",
                        uisref: "visitant-invited-maintenance-new",
                        show: true,
                        class:"animate__animated  animate__backInLeft"
                    },
                    {
                        title: "Empleado",
                        icon: "cleaning_services",
                        authoritites: "ROLE_USER,ROLE_OWNER",
                        uisref: "visitant-invited-employee-new",
                        show: true,
                        class:"animate__animated  animate__backInRight"
                    },
                ]
            }else{
                vm.menu = [
                    {
                        title: "Express",
                        icon: "local_shipping",
                        authoritites: "ROLE_USER,ROLE_OWNER",
                        uisref: "visitant-invited-express-new",
                        show: true,
                        class:"animate__animated  animate__backInLeft"
                    },
                    {
                        title: "Temporal",
                        icon: "hail",
                        authoritites: "ROLE_USER,ROLE_OWNER",
                        uisref: "visitant-invited-temporal-new",
                        show: true,
                        class:"animate__animated  animate__backInRight"
                    },
                    {
                        title: "Permanente",
                        icon: "account_circle",
                        authoritites: "ROLE_USER,ROLE_OWNER",
                        uisref: "visitant-invited-permanent-new",
                        show: true,
                        class:"animate__animated  animate__backInLeft"
                    },
                    {
                        title: "Empleado",
                        icon: "cleaning_services",
                        authoritites: "ROLE_USER,ROLE_OWNER",
                        uisref: "visitant-invited-employee-new",
                        show: true,
                        class:"animate__animated  animate__backInRight"
                    },
                    {
                        title: "Bus escolar",
                        icon: "directions_bus",
                        authoritites: "ROLE_USER,ROLE_OWNER",
                        uisref: "visitant-invited-bus-new",
                        show: true,
                        class:"animate__animated  animate__backInLeft"
                    },
                    {
                        title: "Vacacional",
                        icon: "surfing",
                        authoritites: "ROLE_USER,ROLE_OWNER",
                        uisref: "visitant-invited-vacational-new",
                        show: true,
                        class:"animate__animated  animate__backInRight"
                    },
                ]
            }
        }
    }
)();
