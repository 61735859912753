(function() {
    'use strict';
    angular
        .module('aditumApp')
        .factory('AditumGate', AditumGate);

    AditumGate.$inject = ['$resource'];

    function AditumGate ($resource) {
        var resourceUrl =  'api/aditum-gate/:id';

        return $resource(resourceUrl, {}, {
            'closeGate':{
                method:'GET',
                url:'api/aditum-gate/close/:gateId'
            },
            'openGate':{
                method:'GET',
                url:'api/aditum-gate/open/:gateId'
            },
            'verify':{
                method:'GET',
                url:'api/qr-verify/:id'
            }
        });
    }
})();
