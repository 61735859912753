(function () {
        'use strict';

        angular
            .module('aditumApp')
            .controller('RegisterVisitorController', RegisterVisitorController);

        RegisterVisitorController.$inject = ['GateAccess', 'AditumGate', 'CompanyConfiguration', '$stateParams', 'WSVisitorAuthorizationRequest', '$timeout', 'VisitorAuthorizationRequest', 'ParkingSlot', 'ParkingSlotConfiguration', '$localStorage', 'Resident', 'VisitantInvitation', 'Auth', '$state', '$scope', '$rootScope', 'CommonMethods', 'AlertService', 'PadronElectoral', 'Destinies', 'globalCompany', 'Modal', 'Visitant'];

        function RegisterVisitorController(GateAccess, AditumGate, CompanyConfiguration, $stateParams, WSVisitorAuthorizationRequest, $timeout, VisitorAuthorizationRequest, ParkingSlot, ParkingSlotConfiguration, $localStorage, Resident, VisitantInvitation, Auth, $state, $scope, $rootScope, CommonMethods, AlertService, PadronElectoral, Destinies, globalCompany, Modal, Visitant) {
            var vm = this;
            vm.showLock = true;
            vm.visitorType = 1;
            CommonMethods.validateLetters();
            CommonMethods.validateNumbers();
            CommonMethods.validateSpecialCharacters();
            CommonMethods.validateSpecialCharactersAndVocals();
            vm.showLockCed = false;
            $rootScope.mainTitle = "Registrar visitante";
            vm.foundVisitantInvited = false;
            vm.loadedResidentsInfo = false;
            vm.companions = $localStorage.companions;
            vm.company = $rootScope.company;
            vm.houseSelected = [];
            vm.housesInformation = [];
            if ($rootScope.houseSelected) {
                if ($rootScope.houseSelected != -1) {
                    $timeout(function () {
                        $scope.$apply(function () {
                            vm.houseSelected = [$rootScope.houseSelected.id];
                            vm.loadResidentInfo();
                        })
                    })
                }
            }

            vm.getColorName = function (selectedValue) {
                switch (selectedValue) {
                    case 'darkred':
                        return 'Rojo';
                    case 'darkgreen':
                        return 'Verde';
                    case 'darkblue':
                        return 'Azul';
                    case 'yellow':
                        return 'Amarillo';
                    case 'white':
                        return 'Blanco';
                    case 'darkorange':
                        return 'Naranja';
                    case 'black':
                        return 'Negro';
                    default:
                        return 'Color no definido';
                }
            }

            vm.showFile = function (url) {
                $state.go("access-door.register-visitor.record-file-preview",
                    {
                        url: url,
                        type: "pdf"
                    })
            }

            vm.showPdf = function (url) {
                $state.go("access-door.register-visitor.record-file-preview-pdf",
                    {
                        url: url,
                        type: "pdf"
                    })
            }


            if (!vm.companions) {
                vm.companions = [];
            }
            vm.openAditumGateQr = false;
            vm.authorized = {};

            CompanyConfiguration.get({id: globalCompany.getId()}, function (data) {
                vm.companyConfig = data;
                if (vm.companyConfig.hasAditumGate) {
                    GateAccess.queryByCompany({companyId: globalCompany.getId()}, function (data) {
                        if (data.length > 0) {
                            vm.openAditumGateQr = true;
                        }
                    })
                }
            })

            vm.qrRead = $stateParams.qrRead;

            function findSelectedHouse(houseId) {
                for (var i = 0; i < $rootScope.houses.length; i++) {
                    if ($rootScope.houses[i].id == houseId) {
                        vm.houseSelected = [];
                        vm.houseSelected.push($rootScope.houses[i].id);
                        if (vm.authorized) {
                            vm.authorized.houseNumber = $rootScope.houses[i].housenumber;
                        }
                        break;
                    }
                }
            }

            vm.defineBackground = function (authorized) {
                return "card-visitor-access-door-" + authorized.visitorType.toLowerCase();
            }
            $scope.$watch(function () {
                return $localStorage.companions;
            }, function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    vm.companions = $localStorage.companions;
                }
            });
            vm.authorizationRequestHouseId = null;
            vm.showAuthorizatonResponse = false;
            vm.showRequestBtn = false;

            vm.counter = 35;
            vm.showCounter = false;

            vm.showKeys = function (houseSelected) {
                var emergencyKey, securityKey;
                emergencyKey = houseSelected.emergencyKey == null ? "No definida" : houseSelected.emergencyKey;
                securityKey = houseSelected.securityKey == null ? "No definida" : houseSelected.securityKey;
                Modal.customDialog("<md-dialog>" +
                    "<md-dialog-content class='md-dialog-content text-center'>" +
                    "<h1 class='md-title uppercase font-14'>Clave de seguridad Filial <b>" + houseSelected.housenumber + "</b></h1>" +
                    "<div class='md-dialog-content-body'>" +
                    "<p><span style='font-size: 20px'>" + securityKey + "</span></p>" +
                    "</div>" +
                    "</md-dialog-content>" +
                    "</md-dialog>")
            };

            vm.requestAuthorization = function () {
                Modal.confirmDialog("¿Deseá solicitar autorización a los residentes de la filial?",
                    "La autorización se solicitará mediante la aplicación y podrán ver la respuesta aquí", function () {
                        var request = {
                            visitorName: vm.visitor_name + " " + vm.visitor_last_name + " " + vm.visitor_second_last_name,
                            identificationNumber: vm.visitor_id_number,
                            plateNumber: vm.visitor_license_plate,
                            observation: vm.observation,
                            houseId: vm.houseSelected[vm.houseSelected.length - 1],
                            companyId: globalCompany.getId()
                        }
                        WSVisitorAuthorizationRequest.sendActivity(request, vm.houseSelected[vm.houseSelected.length - 1])
                        $timeout(function () {
                            vm.showCounter = true;
                            vm.countDown();
                        }, 1000)
                    })
            }

            vm.countDown = function () {
                vm.timer = $timeout(function () {
                    vm.counter--;
                    if (vm.counter != -1) {
                        vm.countDown()
                    } else {
                        vm.cancelCountDown()
                    }
                }, 1000)
            }

            vm.cancelCountDown = function () {
                $timeout.cancel(vm.timer);
                vm.counter = 50;
                vm.showCounter = false;
            }


            vm.selectCone = function (parking) {
                vm.parkingSelected = parking;
            }

            vm.update = function () {
                setTimeout(function () {
                    $scope.$apply()
                }, 10)
            }

            vm.applySelectedConeClass = function (parking) {
                return parking.id == vm.parkingSelected;
            }

            vm.save = save;
            Modal.enteringForm(save);

            vm.parkingSelected = {};
            if($rootScope.parkingSlotConfiguration){
                loadParkingSlots();
            }else {
                $timeout(function () {
                    if ($rootScope.parkingSlotConfiguration.enabled) {
                        loadParkingSlots();
                    }
                }, 1000)
            }

            function loadParkingSlots() {
                ParkingSlot.getByCompanyOfficers({companyId: globalCompany.getId()}, function (parkings) {
                    vm.parkingSlots = parkings;
                });
            }

            vm.freeParkingSlot = function (parking) {
                Modal.confirmDialog("¿Está seguro que quiere liberarlo?", "", function () {
                    parking.ocupated = false;
                    parking.usedBy = null;
                    parking.houseId = null;
                    parking.deleted = false;
                    parking.companyId = globalCompany.getId();
                    vm.parkingSlots = [];
                    ParkingSlot.update(parking, function () {
                        loadParkingSlots();
                        Modal.toast("Cono liberado")
                    })
                })
            }

            vm.deleteCompanion = function (companion) {
                Modal.confirmDialog("¿Está seguro que desea eliminar este acompañante?", "", function () {
                    CommonMethods.deleteFromArray(companion, $localStorage.companions)
                    Modal.toast("Listo")
                })
            }
            $scope.$on("$destroy", function () {
                Modal.leavingForm();
                $localStorage.companions = []
            });
            vm.clearSearchTerm = function () {
                vm.searchTerm = '';
            };
            vm.searchTerm;

            vm.typingSearchTerm = function (ev) {
                ev.stopPropagation();
            }

            vm.typingSearchTermParking = function (ev) {
                ev.stopPropagation();
            }

            vm.clearSearchTermParking = function () {
                vm.searchTermParking = '';
            };

            vm.searchTermParking;

            vm.getVisitor = function () {
                // vm.destiny = undefined;
                // vm.houseSelected = undefined;
                vm.visitorType = 1;
                if (vm.visitor_id_number !== undefined && vm.visitor_id_number.length >= 8) {
                    Modal.showLoadingBar();
                    if ($rootScope.online) {
                        VisitantInvitation.getActiveInvitedByCompanyFilter({
                            page: vm.page,
                            size: 1,
                            sort: sortResidents(),
                            companyId: globalCompany.getId(),
                            name: vm.visitor_id_number,
                            houseId: "empty",
                            owner: "empty",
                            enabled: 1,
                        }, function (data) {
                            var invited = data[0];
                            if (invited != undefined) {
                                // if (invited.visitorType == "BUS") {
                                vm.observation = invited.proveedor;
                                // }
                                if (invited.id != null) {
                                    vm.foundVisitantInvited = true;
                                    setFormDB(invited);
                                    setDestiny(invited);
                                    vm.loadResidentInfo();
                                    vm.observation = invited.proveedor;
                                    Modal.hideLoadingBar();
                                } else {
                                    vm.foundVisitantInvited = false;
                                    $timeout(function () {
                                        $scope.$apply(function () {
                                            if ($stateParams.identificationNumber.includes("EXPRESS")) {
                                                vm.houseSelected = [$stateParams.identificationNumber.split("@")[2]];
                                                var plate = $stateParams.identificationNumber.split("@")[3];
                                                if (plate != 'ND') {
                                                    vm.visitor_license_plate = $stateParams.identificationNumber.split("@")[3];
                                                }
                                                vm.authorized.visitorType = "EXPRESS";
                                                vm.foundVisitantInvited = true;
                                            }
                                        })
                                    })
                                    PadronElectoral.find(vm.visitor_id_number, personFindedPadron, personNotFinded)
                                }
                            } else {
                                vm.foundVisitantInvited = false;
                                $timeout(function () {
                                    $scope.$apply(function () {
                                        if ($stateParams.identificationNumber.includes("EXPRESS")) {
                                            vm.houseSelected = [$stateParams.identificationNumber.split("@")[2]];
                                            vm.authorized.visitorType = "EXPRESS";
                                            var plate = $stateParams.identificationNumber.split("@")[3];
                                            if (plate != 'ND') {
                                                vm.visitor_license_plate = $stateParams.identificationNumber.split("@")[3];
                                            }
                                            vm.foundVisitantInvited = true;
                                        }
                                    })
                                })
                                PadronElectoral.find(vm.visitor_id_number, personFindedPadron, personNotFinded)
                            }
                        }, function () {
                        });
                    } else {
                        personNotFinded();
                    }
                }

                function sortResidents() {
                    var result = [];
                    if (vm.predicate !== 'name') {
                        result.push('name,asc');
                    }
                    return result;
                }

                function personFindedPadron(person) {
                    Visitant.getByCompanyIdAndIdentification({
                        companyId: globalCompany.getId(),
                        identification: vm.visitor_id_number
                    }, function (visitor) {
                        person.plate = visitor.licenseplate;
                        setFormPadron(person);
                        // setDestiny(visitor);
                        $timeout(function () {
                            $scope.$apply(function () {
                                if ($stateParams.identificationNumber.includes("EXPRESS")) {
                                    vm.houseSelected = [$stateParams.identificationNumber.split("@")[2]];
                                    vm.authorized.visitorType = "EXPRESS";
                                    vm.foundVisitantInvited = true;
                                    var plate = $stateParams.identificationNumber.split("@")[3];
                                    if (plate != 'ND') {
                                        vm.visitor_license_plate = $stateParams.identificationNumber.split("@")[3];
                                    }
                                }
                            })
                        })
                    }, function () {
                        setFormPadron(person);
                    });
                }

                function personNotFinded() {
                    Visitant.getByCompanyIdAndIdentification({
                        companyId: globalCompany.getId(),
                        identification: vm.visitor_id_number
                    }, function (visitor) {
                        vm.foundVisitantInvited = false;
                        vm.qrRead = false;
                        setFormDB(visitor);
                        setDestiny(visitor);
                    }, function () {
                        nothingFound();
                    });
                }

                $timeout(function () {
                    $scope.$apply(function () {
                        if ($stateParams.identificationNumber.includes("EXPRESS")) {
                            var plate = $stateParams.identificationNumber.split("@")[3];
                            if (plate != 'ND') {
                                vm.visitor_license_plate = $stateParams.identificationNumber.split("@")[3];
                            }
                            vm.houseSelected = [$stateParams.identificationNumber.split("@")[2]];
                            vm.authorized.visitorType = "EXPRESS";
                            vm.foundVisitantInvited = true;
                        }
                    })
                })
            };
            vm.getVisitorByPlate = function () {
                if (vm.visitor_id_number === undefined || vm.visitor_id_number === "") {
                    if (vm.visitor_license_plate) {
                        if (vm.visitor_license_plate.length > 3) {
                            Modal.showLoadingBar();
                            Visitant.getByCompanyIdAndPlate({
                                companyId: globalCompany.getId(),
                                plate: vm.visitor_license_plate
                            }, function (visitor) {
                                setFormDB(visitor);
                                // setDestiny(visitor);
                                Modal.hideLoadingBar();
                            }, function () {
                                // nothingFound();
                                vm.encontrado = 0;
                                setTimeout(function () {
                                    $scope.$apply(function () {
                                        if (vm.encontrado > 0) {
                                            vm.consultingPadron = false;
                                            vm.founded = true;
                                            vm.showLock = true;
                                        } else {
                                            vm.founded = false;
                                            vm.showLock = false;
                                        }
                                    })
                                })
                                Modal.hideLoadingBar();
                            });
                        }
                    }
                }

            };

            function setFormPadron(person) {
                setTimeout(function () {
                        $scope.$apply(function () {
                            vm.showLock = true;
                            vm.visitor_name = person.nombre.split(",")[0] + "";
                            vm.visitor_last_name = person.nombre.split(",")[1] + "";
                            vm.visitor_second_last_name = person.nombre.split(",")[2] + "";
                            vm.consultingPadron = false;
                            vm.showLockCed = true;
                            vm.founded = true;
                            if (person.plate) {
                                vm.visitor_license_plate = person.plate;
                            }
                            Modal.hideLoadingBar();
                            if ($stateParams.identificationNumber.includes("EXPRESS")) {
                                var plate = $stateParams.identificationNumber.split("@")[3];
                                if (plate != 'ND') {
                                    vm.visitor_license_plate = $stateParams.identificationNumber.split("@")[3];
                                }
                                vm.houseSelected = [$stateParams.identificationNumber.split("@")[2]];
                                vm.authorized.visitorType = "EXPRESS";
                                vm.foundVisitantInvited = true;
                            }
                        })
                    }
                )
            }

            vm.getVisitor();

            function nothingFound() {
                vm.encontrado = 0;
                setTimeout(function () {
                    $scope.$apply(function () {
                        if (vm.encontrado > 0) {
                            vm.consultingPadron = false;
                            vm.founded = true;
                            vm.showLock = true;
                        } else {
                            if ($rootScope.online) {
                                Modal.toastGiant("Los datos del visitante no se han encontrado")
                            } else {
                                Modal.toastGiant("No hay conexión a internet,ingresa los datos del visitante manualmente")
                            }
                            vm.founded = false;
                            vm.showLock = false;
                        }
                    })
                }, 10)
                Modal.hideLoadingBar();
            }

            vm.defineType = function (authorized) {
                switch (authorized.visitorType) {
                    case "PERMANENT":
                        return "Permanente";
                    case "VACATIONAL":
                        return "Vacacional";
                    case "BUS":
                        return "Bus escolar";
                    case "EMPLOYEE":
                        return "Empleado";
                    case "TEMPORAL":
                        return "Temporal";
                    case "EXPRESS":
                        return "Express";
                    case "IMPORTATION":
                        return "Importación";
                    case "NATIONAL_MERCHANDISE":
                        return "Ingreso mercadería nacional";
                    case "MERCHANDISE_EXIT":
                        return "Salida de mercadería";
                    case "MAINTENANCE":
                        return "Mantenimiento";
                    default:
                        return "Desconocido";
                }
            };

            vm.previewFileId = function () {
                $state.go("access-door.register-visitor.record-file-preview", {
                    url: "https://firebasestorage.googleapis.com/v0/b/aditum-storage.appspot.com/o/icons-access-door%2FCedula-Costa-Rica.jpg?alt=media&token=f47fa2b8-758f-45d2-9af1-def90970b66a",
                    type: "fa-file-image-o"
                })
            }

            function defineHouseNumber(houseId) {
                if ($rootScope.houses) {
                    for (var i = 0; i < $rootScope.houses.length; i++) {
                        if ($rootScope.houses[i].id == houseId) {
                            return $rootScope.houses[i].housenumber;
                        }
                    }
                }
            }

            function setFormDB(visitor) {
                vm.authorized = visitor;
                vm.authorized.houseNumber = defineHouseNumber(vm.authorized.houseId);
                setTimeout(function () {
                    $scope.$apply(function () {
                        vm.showLock = true;
                        vm.visitor_name = visitor.name;
                        vm.proveedor = visitor.proveedor;
                        vm.visitor_last_name = visitor.lastname;
                        vm.visitor_second_last_name = visitor.secondlastname;
                        vm.visitor_id_number = visitor.identificationnumber;
                        vm.consultingPadron = false;
                        vm.showLockCed = vm.visitor_id_number != undefined;
                        vm.founded = true;
                        vm.invitationPicture = visitor.invitationPicture;
                        vm.observation = visitor.proveedor;
                        Modal.hideLoadingBar();
                        // vm.foundVisitantInvited = true;
                        if ($stateParams.identificationNumber.includes("EXPRESS")) {
                            var plate = $stateParams.identificationNumber.split("@")[3];
                            if (plate != 'ND') {
                                vm.visitor_license_plate = $stateParams.identificationNumber.split("@")[3];
                            }
                            vm.houseSelected = [$stateParams.identificationNumber.split("@")[2]];
                            vm.authorized.visitorType = "EXPRESS";
                            vm.foundVisitantInvited = true;
                        }
                    })
                })
            }


            function setDestiny(visitor) {
                if (globalCompany.getId() != 23) {
                    if (visitor.licenseplate != null) {
                        if (vm.visitor_license_plate === undefined || vm.visitor_license_plate === "") {
                            vm.visitor_license_plate = visitor.licenseplate;
                        }
                    }
                    setTimeout(function () {
                        $scope.$apply(function () {
                            setTimeout(function () {
                                $scope.$apply(function () {
                                    if (visitor.houseId !== null) {
                                        vm.visitorType = 1;
                                        // if (globalCompany.getId() == 62) {
                                        //     if (!visitor.invitationstartingtime) {
                                        //         vm.visitorType = 1;
                                        //     } else {
                                        //         vm.houseSelected = [visitor.houseId];
                                        //     }
                                        // } else {
                                        if (vm.qrRead || vm.foundVisitantInvited) {
                                            vm.houseSelected = [visitor.houseId];
                                        }
                                        // }
                                    } else {
                                        vm.visitorType = 2;
                                        // vm.destiny = visitor.destiny
                                    }
                                    vm.loadResidentInfo();
                                    if ($stateParams.identificationNumber.includes("EXPRESS")) {
                                        vm.houseSelected = [$stateParams.identificationNumber.split("@")[2]];
                                        vm.authorized.visitorType = "EXPRESS";
                                        var plate = $stateParams.identificationNumber.split("@")[3];
                                        if (plate != 'ND') {
                                            vm.visitor_license_plate = $stateParams.identificationNumber.split("@")[3];
                                        }
                                        vm.foundVisitantInvited = true;
                                        vm.visitorType = 1;
                                    }
                                })
                            })
                        })
                    })
                }
            }

            vm.visitorProveedor = function (visitor) {
                if (visitor == null || visitor == undefined || visitor == "") {
                    return false;
                }
                return true;
            }
            vm.changeDestino = function () {
                vm.house = {};
                vm.houseSelected = [];
                vm.destiny = undefined;
                setTimeout(function () {
                    $scope.$apply(function () {
                        $(".input-res1").removeClass("md-input-invalid")

                        $(".select-res").removeClass("ng-pristine ng-empty ng-invalid ng-invalid-required ng-touched")
                    })
                }, 10)

            }
            vm.unlocklock = function () {
                vm.found = false;
                vm.showLockCed = !vm.showLockCed;
            }


            vm.clearAll = function () {
                vm.clearInputs()
                setTimeout(function () {
                    $scope.$apply(function () {
                        $(".input-res").removeClass("md-input-invalid")
                        $(".select-res").removeClass("ng-pristine ng-empty ng-invalid ng-invalid-required ng-touched")
                    })
                }, 10)
            }


            vm.clearInputs = function () {
                vm.visitor_id_number = "";
                vm.visitor_name = ""
                vm.visitor_last_name = "";
                vm.visitor_second_last_name = "";
                vm.visitor_license_plate = "";
                vm.house = {};
                vm.houseSelected = [];
                vm.loadedResidentsInfo = false;
                vm.residentsInfo = [];
                vm.observation = null;
                vm.proveedor = null;
                vm.foundVisitantInvited = false;
                vm.showLockCed = false;
                $rootScope.id_number = undefined;
                $rootScope.id_vehicule = undefined;
                $localStorage.companions = [];
                vm.companions = [];
                vm.showAuthorizatonResponse = false;
                vm.showRequestBtn = false;
                vm.counter = 50;
                vm.showCounter = false;
                vm.authorized = {}
            }
            vm.loadResidentInfo = function () {
                if (vm.houseSelected != undefined) {
                    vm.housesInformation = [];
                    for (var i = 0; i < vm.houseSelected.length; i++) {
                        vm.loadResidents(vm.houseSelected[i])
                        vm.subscribeResponseAuthorization(vm.houseSelected[i])
                    }
                }
            }

            vm.subscribeResponseAuthorization = function (houseId) {
                vm.showRequestBtn = false;
                VisitorAuthorizationRequest.isAbleToSend({houseId: houseId}, function (data) {
                    $timeout(function () {
                        $scope.$apply(function () {
                            if (data.status == 1) {
                                vm.showRequestBtn = true;
                                WSVisitorAuthorizationRequest.unsubscribeResponse(vm.authorizationRequestHouseId)
                                vm.authorizationRequestHouseId = houseId;
                                WSVisitorAuthorizationRequest.subscribeResponse(vm.authorizationRequestHouseId)
                                WSVisitorAuthorizationRequest.receiveResponse(vm.authorizationRequestHouseId).then(null, null, receiveAuthorizationResponse);
                            }
                        })
                    })
                })
            }

            function receiveAuthorizationResponse(data) {
                $timeout(function () {
                    $scope.$apply(function () {
                        vm.showAuthorizatonResponse = true;
                        vm.authorizationResponse = data.status;
                        vm.showRequestBtn = false;
                        vm.cancelCountDown();
                    })
                })
            }

            vm.defineRoleUser = function (resident) {
                for (var i = 0; i < resident.houses.length; i++) {
                    switch (resident.houses[i].roleResident) {
                        case "ROLE_OWNER":
                            resident.houses[i].role = "Residente propietario";
                            break;
                        case "ROLE_RENTER":
                            resident.houses[i].role = "Propietario arrendador";
                            break;
                        case "ROLE_TENANT":
                            resident.houses[i].role = "Inquilino";
                            break;
                        case "ROLE_RESIDENT":
                            resident.houses[i].role = "Residente autorizado";
                            break;
                    }
                }
                return resident;
            }

            function defineRole(role) {
                switch (role) {
                    case "ROLE_OWNER":
                        return "Residente propietario";
                    case "ROLE_RENTER":
                        return "Propietario arrendador";
                    case "ROLE_TENANT":
                        return "Inquilino";
                    case "ROLE_RESIDENT":
                        return "Residente autorizado";
                }
                return "ROLE_OWNER";
            }

            function formatResidentList(role, data, houseId, house) {
                var list = [];
                for (var i = 0; i < data.length; i++) {
                    var r = data[i];
                    for (var j = 0; j < r.residentHousePK.length; j++) {
                        var h = r.residentHousePK[j];
                        if (h.houseId == houseId) {
                            r.role = h.role;
                        }
                    }
                    if (r.role == role) {
                        r.roleDescription = defineRole(r.role)
                        r = vm.defineRoleUser(r);
                        list.push(r)
                    }
                }
                list.sort(function (a, b) {
                    if (a.id == house.principalContact && b.id != house.principalContact) {
                        return -1; // a debe ir primero
                    } else if (a.id != house.principalContact && b.id == house.principalContact) {
                        return 1; // b debe ir primero
                    } else {
                        return 0; // no se cambia el orden
                    }
                });
                return list;
            }

            vm.loadResidents = function (houseId) {
                var house = {};
                for (var i = 0; i < $rootScope.houses.length; i++) {
                    if ($rootScope.houses[i].id == houseId) {
                        var houseSelected = $rootScope.houses[i];
                        house = houseSelected;
                        house.houseInfo = houseSelected.housenumber;
                        house.houseInfo = houseSelected.housenumber;
                        house.phoneFijo = houseSelected.extension != null ? house.extension : "No definido";
                    }
                }
                vm.owners = [];
                vm.tenants = [];
                vm.residents = [];
                vm.data = [];
                vm.residentsFilter = [];
                vm.query = "";
                vm.loadedResidentsInfo = false;
                if (house != -1) {
                    vm.loading = true;
                    Resident.getResidentsByHouse({houseId: houseId}, function (data) {
                        vm.owners = formatResidentList("ROLE_OWNER", data, houseId, house);
                        vm.owners = vm.owners.concat(formatResidentList("ROLE_RENTER", data, houseId, house));
                        vm.tenants = formatResidentList("ROLE_TENANT", data, houseId, house);
                        vm.residents = formatResidentList("ROLE_RESIDENT", data, houseId, house);
                        vm.loading = false;
                        house.data = data;
                        vm.loadedResidentsInfo = true;
                        house.owners = vm.owners;
                        house.tenants = vm.tenants;
                        house.residents = vm.residents;
                    })
                }
                vm.housesInformation.push(house);
            }


            function functionDefineParkingSpot(visitor) {
                if (vm.parkingSelected.id) {
                    visitor.parkingSlotId = vm.parkingSelected.id
                }
                return visitor;
            }

            function getHouseNumber(id, destiny) {
                if (destiny) {
                    return destiny;
                } else {
                    for (var i = 0; i < $rootScope.houses.length; i++) {
                        if (id == $rootScope.houses[i].id) {
                            return $rootScope.houses[i].housenumber;
                        }
                    }
                }
            }

            function save() {
                var valid = false;
                if (vm.visitor_id_number.length < 8) {
                    Modal.toastGiant("El formato de la cédula no es correcto, debe de tener al menos 8 dígitos")
                } else {
                    if (vm.visitorType == 1) {
                        if (vm.houseSelected === undefined) {
                            Modal.toastGiant("Debe seleccionar la filial que visita")
                        } else {
                            valid = true;
                        }
                    } else {
                        if (vm.destiny === undefined) {
                            Modal.toastGiant("Debe seleccionar el destino que visita")
                        } else {
                            valid = true;
                        }
                    }
                    if (valid) {
                        var companionText = "";
                        if (vm.companions.length != 0) {
                            companionText = "Se registrará junto con los acompañantes"
                        }
                        Modal.confirmDialog("¿Está seguro que desea registrar la visita en " + getHouseNumber(vm.houseSelected[0], vm.destiny) + "?", companionText, function () {
                            Modal.showLoadingBar();
                            Modal.showLoadingBar();
                            if (vm.visitorType == 2) {
                                var visitor = {
                                    name: vm.visitor_name.toUpperCase(),
                                    lastname: vm.visitor_last_name.toUpperCase(),
                                    secondlastname: vm.visitor_second_last_name !== undefined && vm.visitor_second_last_name !== null ? vm.visitor_second_last_name.toUpperCase() : undefined,
                                    identificationnumber: vm.visitor_id_number.toUpperCase(),
                                    licenseplate: vm.visitor_license_plate !== undefined ? vm.visitor_license_plate.toUpperCase() : undefined,
                                    companyId: globalCompany.getId(),
                                    isinvited: 4,
                                    responsableofficer: vm.destiny,
                                    arrivaltime: moment(new Date()).format(),
                                    houseId: undefined,
                                    observation: vm.observation,
                                    proveedor: vm.proveedor,
                                    dua: vm.authorized.dua,
                                    visitorType: vm.authorized.visitorType,
                                    authorizedId: vm.authorized.id
                                }
                                visitor.invitationPicture = vm.invitationPicture;
                                visitor.responsableofficer = vm.destiny;
                                visitor.houseId = undefined;
                                visitor = functionDefineParkingSpot(visitor);
                                if (vm.companions.length != 0) {
                                    var count = 0;
                                    for (var i = 0; i < vm.companions.length; i++) {
                                        var companion = vm.companions[i];
                                        companion.arrivaltime = moment(new Date()).format()
                                        companion.houseId = visitor.houseId;
                                        Visitant.save(companion, function () {
                                            count++;
                                            if (count == vm.companions.length) {
                                                Visitant.save(visitor, onSaveSuccess, onSaveError);
                                            }
                                        });
                                    }
                                } else {
                                    Visitant.save(visitor, onSaveSuccess, onSaveError);
                                }
                            } else {
                                for (var i = 0; i < vm.houseSelected.length; i++) {
                                    var visitor = {
                                        name: vm.visitor_name.toUpperCase(),
                                        lastname: vm.visitor_last_name.toUpperCase(),
                                        secondlastname: vm.visitor_second_last_name !== undefined && vm.visitor_second_last_name !== null ? vm.visitor_second_last_name.toUpperCase() : undefined,
                                        identificationnumber: vm.visitor_id_number.toUpperCase(),
                                        licenseplate: vm.visitor_license_plate !== undefined ? vm.visitor_license_plate.toUpperCase() : undefined,
                                        companyId: globalCompany.getId(),
                                        isinvited: 4,
                                        responsableofficer: vm.destiny,
                                        arrivaltime: moment(new Date()).format(),
                                        houseId: vm.houseSelected[i],
                                        observation: vm.observation,
                                        proveedor: vm.proveedor,
                                        dua: vm.authorized.dua,
                                        visitorType: vm.authorized.visitorType,
                                        authorizedId: vm.authorized.id
                                    }
                                    visitor.invitationPicture = vm.invitationPicture;
                                    visitor = functionDefineParkingSpot(visitor);
                                    if (vm.companions.length != 0) {
                                        var count = 0;
                                        for (var i = 0; i < vm.companions.length; i++) {
                                            var companion = vm.companions[i];
                                            companion.arrivaltime = moment(new Date()).format()
                                            companion.houseId = vm.houseSelected[i];
                                            Visitant.save(companion, function () {
                                                count++;
                                                if (count == vm.companions.length) {
                                                    Visitant.save(visitor, onSaveSuccess, onSaveError);
                                                }
                                            });
                                        }
                                    } else {
                                        Visitant.save(visitor, onSaveSuccess, onSaveError);
                                    }
                                }
                            }
                        })
                    }
                }
            };

            function onSaveSuccess(result) {
                Modal.toastGiant("Se registró la entrada del visitante correctamente.");
                vm.clearInputs();
                Modal.hideLoadingBar();
                vm.searchTermParking = '';
                vm.houseSelected = [];
                vm.destiny = undefined;
                vm.visitorType = 1;
                $localStorage.companions = [];
                vm.companions = [];
                vm.parkingSlots = [];
                vm.parkingSelected = {};
                loadParkingSlots();

                if (vm.openAditumGateQr && vm.qrRead && $rootScope.qrOpenGate != null) {
                    AditumGate.openGate({gateId: $rootScope.qrOpenGate}, function (data) {
                        if (data.result) {
                        } else {
                            setTimeout(function () {
                                $scope.$apply(function () {
                                    Modal.toast("No se pudo contactar con la puerta.")
                                })
                            }, 1000)
                        }
                    }, function () {
                    })
                    $rootScope.qrOpenGate = null;
                }
                $rootScope.qrOpenGate = null;
                // $state.go("access-door.visitors");
                setTimeout(function () {
                    $scope.$apply(function () {
                        $(".input-res").removeClass("md-input-invalid")
                        $(".select-res").removeClass("ng-pristine ng-empty ng-invalid ng-invalid-required ng-touched")
                    })
                }, 10)
            }

            function onSaveError() {
                Modal.toastGiant("No hay conexión a internet, Se registrará la visita una vez la conexión haya vuelto");
                Modal.hideLoadingBar();
                vm.clearInputs();
                vm.houseSelected = [];
                vm.destiny = undefined;
                setTimeout(function () {
                    $scope.$apply(function () {
                        $(".input-res").removeClass("md-input-invalid")
                        $(".select-res").removeClass("ng-pristine ng-empty ng-invalid ng-invalid-required ng-touched")
                    })
                }, 10)

            }

            if ($rootScope.selectedAuthorized != undefined) {
                if (!$stateParams.identificationNumber.includes("EXPRESS")) {
                    if ($stateParams.identificationNumber == 'authorized') {
                        vm.selectedAuthorized = $rootScope.selectedAuthorized;
                        setFormDB(vm.selectedAuthorized);
                        setDestiny(vm.selectedAuthorized);
                        vm.loadResidentInfo();
                        vm.foundVisitantInvited = true;
                        vm.observation = vm.selectedAuthorized.proveedor;
                    } else {
                        $rootScope.selectedAuthorized = undefined;
                    }
                } else {
                    vm.observation = $stateParams.identificationNumber.split("@")[1];
                    vm.foundVisitantInvited = true;
                    vm.authorized.visitorType = "EXPRESS";
                    $timeout(function () {
                        $scope.$apply(function () {
                            findSelectedHouse($stateParams.identificationNumber.split("@")[2]);
                        })
                    })
                }
            }
        }
    }
)
();
