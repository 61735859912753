(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('DashboardController', DashboardController).value('googleChartApiConfig', {
        version: '1.1',
        optionalSettings: {
            packages: ['bar'],
            language: 'es'
        }
    });

    DashboardController.$inject = ['House','CompanyConfiguration','AditumGate','WSGate','GateAccess','MultiCompany', 'FollowUp', 'FollowUpNextStep', '$scope', '$rootScope', 'Principal', 'LoginService', '$state', 'Dashboard', 'globalCompany', 'Modal', '$timeout', 'CommonAreaReservations', 'CommonMethods'];

    function DashboardController(House,CompanyConfiguration,AditumGate,WSGate,GateAccess,MultiCompany, FollowUp, FollowUpNextStep, $scope, $rootScope, Principal, LoginService, $state, Dashboard, globalCompany, Modal, $timeout, CommonAreaReservations, CommonMethods) {
        var vm = this;
        $rootScope.active = "dashboard";
        $rootScope.mainTitle = "Centro de control";
        var companyConfig = CommonMethods.getCurrentCompanyConfig(globalCompany.getId());
        vm.followUpNextSteps = [];
        vm.showGateMenu = function () {
            vm.menuGateShow = !vm.menuGateShow;
        }
        CompanyConfiguration.get({id: globalCompany.getId()}, function (data) {
            vm.companyConfig = data;
            if (vm.companyConfig.hasAditumGate) {
                GateAccess.queryByCompany({companyId: globalCompany.getId()}, function (data) {
                    if (data.length > 0) {
                        vm.menuGate = {
                            title: "Puertas de acceso",
                            icon: "https://firebasestorage.googleapis.com/v0/b/aditum-storage.appspot.com/o/aditum%2Fdoor-e4ac1b6781.svg?alt=media&token=663000e1-c2bd-4e5e-8577-75551b5d91a6",
                            authoritites: "ROLE_USER,ROLE_OWNER",
                            uisref: "emergency.new",
                            show: true,
                            class: "animate__animated animate__backInDown"
                        }
                        vm.menuGates = data[0].gates;
                        vm.menuGate.show = true;
                        vm.accesDoorId = data[0].id;
                    } else {
                        vm.menuGate.show = false;
                    }
                })
            }
        })

        House.query({
            companyId: globalCompany.getId()
        }, function(data){
            vm.totalHouses = data.length;
        }, onError);

        vm.openGate = function (gate) {
            Modal.confirmDialog("¿Está seguro que desea accionar la puerta?", "" +
                "Quedará un registro cada vez que la accione.", function () {
                gate.disable = true;
                AditumGate.openGate({gateId: gate.id}, function (data) {
                    if (data.result) {
                        Modal.toast("Puerta " + gate.description + " está en movimiento.")
                        gate.movement = true;
                        sendWSGateOpen(gate);
                        setTimeout(function () {
                            $scope.$apply(function () {
                                gate.movement = false;
                                gate.disable = false;
                            })
                        }, 5000)
                    } else {
                        setTimeout(function () {
                            $scope.$apply(function () {
                                gate.movement = false;
                                gate.disable = false;
                                Modal.toast("No se pudo contactar con la puerta.")
                            })
                        }, 1000)
                    }
                }, function () {
                })
            })
        }

        function sendWSGateOpen(gate) {
            Principal.identity().then(function (data) {
                var bitacora = {
                    id: null,
                    concept: "Puerta " + gate.description + " accionada por la Administración del condominio",
                    type: 30,
                    idResponsable: data.id,
                    category: "Puertas Acceso",
                    houseId: null,
                    companyId: globalCompany.getId(),
                    houseNumber: $rootScope.houseSelected.housenumber,
                }
                WSGate.sendActivity(bitacora);
            })
        }

        setTimeout(function () {
            if (companyConfig.hasContability == 1) {
                vm.hasContability = true;
            } else {
                vm.hasContability = false;
            }
            if (CommonMethods.getInitialConfig(globalCompany.getId()).initialConfiguration == 0 && CommonMethods.getInitialConfig(globalCompany.getId()).hasContability == 1) {
                $state.go('dashboard.initialConfiguration');
            }
        }, 300)


        vm.ready = false;
        vm.year = moment(new Date()).format("YYYY");
        vm.visitorTitle = "De la semana";
        vm.charTypes = [{name: "Gráfico de barras", type: "ColumnChart"}, {name: "Gráfico de area", type: "AreaChart"}]
        vm.chartTypeEIB = vm.charTypes[0];
        vm.chartTypeDefaulters = vm.charTypes[1];
        vm.visitantState = 1;
        vm.dashboard = {reservationsPending: "-", communicationsPending: "-", complaintsPending: "-"}
        var ieDone = false;
        var porCobrarDone = false;

        var monthsText = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Setiembre", "Octubre", "Noviembre", "Diciembre"]

        vm.loadAll = function () {
            Dashboard.query({companyId: globalCompany.getId()}, function (result) {
                vm.dashboard = result;
                vm.isReady = true;
            });

            FollowUp.queryByCompany({
                page: 0,
                size: 1000,
                companyId: globalCompany.getId(),
            }, onSuccessFollowUp, onError);
        };


        vm.defineIfollowUpIsShowed = function (fNext) {
            return fNext.name != " " || fNext.name != undefined || fNext.name != null || fNext.description != " " || fNext.description != undefined || fNext.description != null;
        }

        function onError(error) {
            // AlertService.error(error.data.message);
        }

        Principal.identity().then((function (data) {
            MultiCompany.getCurrentUserCompany().then(function (data) {
                vm.userId = data.id;
            })
        }))

        function onSuccessFollowUpNextSteps(data, headers) {
            vm.totalItemsFollowUpNextStep = headers('X-Total-Count');
            vm.queryCountFollowUpNextStep = vm.totalItemsFollowUpNextStep;
            var date = new Date();
            for (var i = 0; i < data.length; i++) {
                if (vm.defineIfollowUpIsShowed(data[i])) {
                    if (date.getTime() >= new Date(data[i].reminderDate).getTime()) {
                        data[i].due = true;
                    } else {
                        data[i].due = false;
                    }
                    vm.followUpNextSteps.push(data[i])
                }
            }
            vm.followUpNextStepsReady = true;
            initCalendar();
        }

        vm.goToFollowUp = function (task) {
            if (task.houseId != null) {
                var houseIdF = task.houseId;
            } else {
                var houseIdF = -1

            }
            var houseId = CommonMethods.encryptString(houseIdF + "");
            var id = CommonMethods.encryptString(task.followUpId + "");
            $state.go("follow-up.edit", {houseId: houseId, id: id});
        }

        vm.goToFollowUpF = function (followUp) {
            if (followUp.houseId != null) {
                var houseIdF = followUp.houseId;
            } else {
                var houseIdF = -1
            }
            var houseId = CommonMethods.encryptString(houseIdF + "");
            var id = CommonMethods.encryptString(followUp.id + "");
            $state.go("follow-up.edit", {houseId: houseId, id: id});
        }


        function onSuccessFollowUp(data, headers) {
            vm.totalItemsFollowUp = headers('X-Total-Count');
            vm.queryCountFollowUp = vm.totalItemsFollowUp;
            vm.followUps = data;
            vm.followUpsPendings = [];
            vm.followUpsProccess = [];
            vm.followUpsReadies = []
            var date = new Date();
            for (var i = 0; i < vm.followUps.length; i++) {
                if (date.getTime() >= new Date(vm.followUps[i].dueDate).getTime()) {
                    vm.followUps[i].due = true;
                } else {
                    vm.followUps[i].due = false;
                }
                switch (vm.followUps[i].status) {
                    case "0":
                        vm.followUpsPendings.push(vm.followUps[i]);
                        break;
                    case "1":
                        vm.followUpsProccess.push(vm.followUps[i]);
                        break;
                    case "2":
                        vm.followUpsReadies.push(vm.followUps[i]);
                        break;
                }
            }
            vm.followUpsReady = true;
            FollowUpNextStep.queryByCompany({
                page: 0,
                size: 1000,
                companyId: globalCompany.getId(),
            }, onSuccessFollowUpNextSteps, onError);

        }


        vm.loadDefaulters = function (year) {
            Dashboard.defaulters({companyId: globalCompany.getId(), year: year}, function (result) {
                vm.defaulters = result;
                createMonthsArray(year);
                definePorCobrarDelMes()
                $timeout(function () {
                    defaultersGraphInit(vm.chartTypeDefaulters.type)
                }, 110)
            });
        }

        function createMonthsArray(year) {
            vm.monthsDefaultersOptions = [];
            var d = new Date();
            var n = d.getMonth();
            if (year != vm.year) {
                n = 11
            }
            var monthsDefaultersOptions = [];
            for (var i = 0; i <= n; i++) {
                monthsDefaultersOptions.push({id: i, text: monthsText[i] + " - " + year})
            }
            $timeout(function () {
                vm.monthsDefaultersOptions = monthsDefaultersOptions;
                if (year != vm.year) {
                    vm.monthDefaulter = vm.monthsDefaultersOptions[0].id;
                } else {
                    vm.monthDefaulter = vm.monthsDefaultersOptions[n].id;
                }
            }, 10)
        }

        function definePorCobrarDelMes() {
            if (porCobrarDone === false) {
                var m = new Date().getMonth();
                var monthData = vm.defaulters[m];
                vm.porCobrar = monthData.debtFormat;
                porCobrarDone = true;
            }
        }

        function defaulterMonthGraphInit(month) {
            var monthData = vm.defaulters[month]
            var rows = [];
            var colums = [];
            colums.push({"v": "Liquidado"});
            colums.push({"v": monthData.total, "f": monthData.totalFormated});
            rows.push({"c": colums})
            var colums = [];
            colums.push({"v": "Por cobrar"});
            colums.push({"v": monthData.debt, "f": monthData.debtFormat});
            rows.push({"c": colums})
            vm.dataDefaulterPerMonth = {
                "type": "PieChart",
                "displayed": false,
                "cssStyle": "height:600px;width: 100%",
                "data": {
                    "cols": [
                        {
                            "id": "enable",
                            "label": "enable",
                            "type": "string"
                        },
                        {
                            "id": "enable-id",
                            "label": "Liquidado",
                            "type": "number"
                        },
                        {
                            "id": "disable-id",
                            "label": "Por cobrar",
                            "type": "number"
                        }
                    ],
                    "rows": rows
                },
                "options": {
                    "title": "",
                    "legend": {"position": "bottom"},
                    "isStacked": "false",
                    "fill": 200000,
                    "sliceVisibilityThreshold": 0,
                    "animation": {
                        duration: 1000,
                        easing: 'out',
                    },
                    'chartArea': {'width': '90%', 'height': '78%'},
                    "displayExactValues": true,
                    colors: ['#001e2f', '#deb66e']
                }
            }
        }

        vm.changeMonthDefaulter = function (month) {
            $timeout(function () {
                defaulterMonthGraphInit(month)
            })
        };

        function defaultersGraphInit(type) {
            var defaultersPerMonth = vm.defaulters;
            var rows = []
            for (var i = 0; i < defaultersPerMonth.length; i++) {
                rows.push(colsPerMonthDefaulters(defaultersPerMonth[i], i))
            }
            vm.dataDefaulters = {
                "type": type,
                "displayed": false,
                "cssStyle": "height:600px;width: 100%",
                "data": {
                    "cols": [
                        {
                            "id": "month",
                            "label": "Mes",
                            "type": "string",
                        },
                        {
                            "id": "defaulter-id",
                            "label": "Morosos",
                            "type": "number"
                        },
                        {
                            "id": "Vigentes-id",
                            "label": "Vigentes",
                            "type": "number"
                        },
                    ],
                    "rows": rows
                },
                "options": {
                    // titleTextStyle: {
                    //     color: "#4DB3A2",    // any HTML string color ('red', '#cc00cc')
                    //     fontName: "Open Sans", // i.e. 'Times New Roman'
                    //     fontSize: 15, // 12, 18 whatever you want (don't specify px)
                    //     bold: true,    // true or false
                    //     margin:20
                    // },
                    // "title": "MOROSIDAD POR FILIALES PRIVATIVAS",
                    "curveType": "function",
                    "legend": {"position": "bottom"},
                    "isStacked": "true",
                    "sliceVisibilityThreshold": 0,
                    "fill": 10,
                    "animation": {
                        duration: 1000,
                        easing: 'out',
                    },
                    'chartArea': {'width': '90%'},
                    "displayExactValues": true,
                    series: {
                        0: {color: '#deb66e'},
                        1: {color: '#001e2f'},
                    }
                    // "vAxis": {
                    //     "title": "Salesunit",
                    //     "gridlines": {
                    //         "count": 10
                    //
                    //     }
                    // },
                    // "hAxis": {
                    //     "title": "Date"
                    // }
                }
            };

            defaulterMonthGraphInit(vm.monthDefaulter)
        }


        function createYearsArrays() {
            var d = new Date();
            var year = d.getFullYear();
            var yearsIEB = [];
            yearsIEB.push(year)
            for (var i = 1; i <= 3; i++) {
                yearsIEB.push(year - i)
            }
            vm.yearsIEB = yearsIEB;
            vm.yearsDefaulters = yearsIEB;
            vm.yearIEB = yearsIEB[0];
            vm.yearDefaulter = yearsIEB[0];
        }

        vm.showYearDefaulter = function () {
            // vm.loadDefaulters(vm.yearDefaulter)
        }

        vm.loadAll();

        function getColumnChart(title, val) {
            return {"v": val, "f": title}
        }

        function colsPerMonthDefaulters(monthData, i) {
            var colums = [];
            colums.push({"f": monthsText[i]});
            colums.push(getColumnChart(monthData.totalHousesDefaulter + " filiales", monthData.totalHousesDefaulter));
            colums.push(getColumnChart(monthData.totalHousesOnTime + " filiales", monthData.totalHousesOnTime));
            return {"c": colums}
        }

        vm.changeChartTypeDefaulters = function (type) {
            $timeout(function () {
                defaultersGraphInit(type)
            })
        }

        function initCalendar() {
            /* config object */
            // vm.loadDefaulters(vm.year);
            vm.uiConfig = {
                calendar: {
                    events: function (start, end, timezone, callback) {
                        var events = [];
                        CommonAreaReservations.getLastAcceptedReservations({
                            companyId: globalCompany.getId()
                        }, function (data) {
                            angular.forEach(data, function (value) {
                                var color;
                                if (value.status == 1) {
                                    color = '#ef5350'
                                } else if (value.status == 2) {
                                    color = '#42a5f5'
                                }
                                events.push({
                                    id: value.id,
                                    commonAreaId: value.commonAreaId,
                                    title: value.commonArea.name + " - " + value.resident.name + " " + value.resident.lastname + " - Filial " + value.house.housenumber,
                                    start: new Date(value.initalDate),
                                    end: new Date(value.finalDate),
                                    description: 'This is a cool event',
                                    color: color,
                                    status: value.status
                                })
                            });
                            vm.readyCalendar = true;
                            callback(events);
                        });
                    },
                    columnHeader: false,
                    dayClick: vm.onDayClick,
                    editable: false,
                    header: false,
                    height: 257,
                    eventClick: vm.alertOnEventClick,
                    eventDrop: vm.alertOnDrop,
                    eventResize: vm.alertOnResize,
                    eventRender: vm.eventRender,
                    defaultView: 'listYear',
                    default: 'bootstrap3',
                }
            };
        }
    }
})
();
