(function () {
        'use strict';

        angular
            .module('aditumApp')
            .controller('FinancesMobileMenuController', FinancesMobileMenuController);

        FinancesMobileMenuController.$inject = ['$localStorage', '$timeout', '$scope', '$stateParams', '$rootScope', 'CommonMethods', 'globalCompany', '$state'];

        function FinancesMobileMenuController($localStorage, timeout, $scope, $stateParams, $rootScope, CommonMethods, globalCompany, $state) {
            var vm = this;
            $rootScope.active = "finances-mobile-menu";
            $rootScope.mainTitle = "Finanzas";
            var companyConfig = CommonMethods.getCurrentCompanyConfig(globalCompany.getId());
            vm.showEstadoResultados = companyConfig.showEstadoResultados;
            vm.authorities = $localStorage.houseSelected.roleResident;
            vm.showEstadoResultadosGlobal = function () {
                if (vm.showEstadoResultados) {
                    if (globalCompany.getId() == 45)
                        if ($localStorage.houseSelected) {
                            var role = $localStorage.houseSelected.roleResident;
                            if (role == "ROLE_OWNER" || role == "ROLE_RENTER") {
                                return true;
                            } else {
                                return false;
                            }
                        }
                    return true;
                }
                return false;
            };
            vm.go = function (sref) {
                $rootScope.backState = $state.current.name
                $state.go(sref)
            }

            if (vm.authorities == "ROLE_TENANT") {
                vm.menu = [
                    {
                        title: "Mis deudas",
                        icon: "request_quote",
                        authoritites: "ROLE_USER,ROLE_OWNER",
                        uisref: "chargePerHouse-residentAccount",
                        show: companyConfig.hasContability == 1,
                        class: "animate__animated  animate__backInLeft"
                    },
                    {
                        title: "Enviar comprobante de pago",
                        icon: "send",
                        authoritites: "ROLE_USER,ROLE_OWNER",
                        uisref: "paymentProof.new",
                        show: companyConfig.hasContability == 1,
                        class: "animate__animated  animate__backInRight"
                    }
                ]
            } else {
                vm.menu = [
                    {
                        title: "Mi estado de cuenta",
                        icon: "account_balance_wallet",
                        authoritites: "ROLE_USER,ROLE_OWNER",
                        uisref: "accountStatus-residentAccount",
                        show: companyConfig.hasContability == 1,
                        class: "animate__animated  animate__backInLeft"
                    },
                    {
                        title: "Mis deudas",
                        icon: "request_quote",
                        authoritites: "ROLE_USER,ROLE_OWNER",
                        uisref: "chargePerHouse-residentAccount",
                        show: companyConfig.hasContability == 1,
                        class: "animate__animated  animate__backInRight"
                    },
                    {
                        title: "Mis Pagos",
                        icon: "payments",
                        authoritites: "ROLE_USER,ROLE_OWNER",
                        uisref: "paymentsPerHouse-residentAccount",
                        show: companyConfig.hasContability == 1,
                        class: "animate__animated  animate__backInLeft"

                    },
                    {
                        title: "Comprobantes de pago",
                        icon: "credit_score",
                        authoritites: "ROLE_USER,ROLE_OWNER",
                        uisref: "paymentProof.pending-user",
                        show: companyConfig.hasContability == 1 || globalCompany.getId() == 99 || globalCompany.getId() == 100 || globalCompany.getId() == 102,
                        class: "animate__animated  animate__backInRight"

                    },
                    {
                        title: "Estados financieros",
                        icon: "auto_stories",
                        authoritites: "ROLE_USER,ROLE_OWNER",
                        uisref: "mensual-billing-file",
                        show: vm.showEstadoResultadosGlobal() && companyConfig.hasContability == 1,
                        class: "animate__animated  animate__backInLeft"
                    },
                ]
            }

        }
    }

)();
