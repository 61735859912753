(function () {
        'use strict';

        angular
            .module('aditumApp')
            .controller('CommonAreaReservationMobileMenuController', CommonAreaReservationMobileMenuController);

        CommonAreaReservationMobileMenuController.$inject = ['$timeout', '$scope', '$stateParams', '$rootScope', '$state'];

        function CommonAreaReservationMobileMenuController(timeout, $scope, $stateParams, $rootScope, $state) {
            var vm = this;
            $rootScope.active = "common-area-administration-mobile-menu";
            $rootScope.mainTitle = "Reservas";
            vm.go = function (sref) {
                $rootScope.backState = $state.current.name
                $state.go(sref)
            }
            vm.menu = [
                {
                    title: "Realizar reserva",
                    icon: "event",
                    authoritites: "ROLE_USER,ROLE_OWNER",
                    uisref: "common-area-reservation-resident-view",
                    show: true,
                    class: "animate__animated  animate__backInLeft"

                },
                {
                    title: "Mis reservaciones",
                    icon: "calendar_today",
                    authoritites: "ROLE_USER,ROLE_OWNER",
                    uisref: "common-area-all-reservations-resident-view",
                    show: true,
                    class: "animate__animated  animate__backInRight"
                },
                {
                    title: $rootScope.company.fullControlAccess? "Áreas comunes":"Amenidades",
                    icon: "local_florist",
                    authoritites: "ROLE_USER,ROLE_OWNER",
                    uisref: "common-area-resident-account",
                    show: true,
                    class: "animate__animated  animate__backInLeft"

                },
            ]

        }
    }

)();
